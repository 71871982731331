import {SERVER_API_URL} from 'app/app.constants';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {IDriver, Driver} from 'app/blocks/model/driver.model';
import {Resource} from 'app/constants/resource';

import EntityCrudService from 'app/blocks/service/api/entity-service';
import {IDriversVehicles} from 'app/blocks/model/drivers-vehicles.model';
import {Observable} from 'rxjs';
import {ErrorSeverity, createRequestContext, createRequestOption, createRequestOption2} from 'app/blocks/util/request-util';
import moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class DriverService extends EntityCrudService<IDriver> {
    constructor(http: HttpClient) {
        super(http, Resource.DRIVERS, (obj?) => new Driver(obj));
    }

    getAllDriversForPlanning(filters): Promise<any> {
        const params = createRequestOption2(filters);

        return this.http
            .get<IDriver[]>(`${SERVER_API_URL}api/drivers-resource`, {
                params: params
            })
            .toPromise();
    }

    getSectionHTML = (): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/drivers-section`).toPromise();
    };

    getDefaultVehicle = (driverId: number): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/drivers/${driverId}/default-vehicle`).toPromise();
    };

    getDriversVehicles = (driverId: number): Promise<IDriversVehicles> => {
        return this.http.get<IDriversVehicles>(`${SERVER_API_URL}api/drivers/${driverId}/vehicles`).toPromise();
    };

    getDriversTrailers = (driverId: number): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/drivers/${driverId}/trailers`).toPromise();
    };

    public updateTravelNotes = (id: number, travelNote: string): Promise<any[]> => {
        let params = new HttpParams();
        params = params.append('driver_id', id);
        const REQUEST_URI = `${SERVER_API_URL}api/drivers/travel-note?driver_id=${id}&travel_note=${travelNote}`;
        return this.http.post<any>(REQUEST_URI, {observe: 'response'}).toPromise();
    };

    getDriversComingIn(
        legStatuses?: string,
        customerId?: number,
        orderNumber?: number,
        runId?: number,
        driverId?: number,
        vehicleId?: number,
        trailerId?: number,
        startDate?: string[],
        endDate?: string[],
        businessTypeIds?: string,
        trafficAreaIds?: string,
        controlAreaIds?: string,
        errorSeverity?: ErrorSeverity
    ): Promise<any> {
        const REQUEST_URI = SERVER_API_URL + 'api/drivers/coming-in';
        let REQUEST_PARAMS = new HttpParams().set('businessTypeIds', businessTypeIds).set('trafficAreaIds', trafficAreaIds);
        if (controlAreaIds && controlAreaIds.length > 0) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('controlAreaIds', controlAreaIds[0]);
        }
        if (startDate && startDate.length === 2) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('startDateFrom', startDate[0]).set('startDateTo', startDate[1]);
        }
        if (endDate && endDate.length === 2) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('endDateFrom', endDate[0]).set('endDateTo', endDate[1]);
        }
        if (legStatuses) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('legStatuses', legStatuses);
        }
        if (customerId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('customer', customerId);
        }
        if (orderNumber) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('orderNumber', orderNumber);
        }
        if (runId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('runId', runId);
        }
        if (driverId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('driverId', driverId);
        }
        if (vehicleId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('vehicleId', vehicleId);
        }
        if (trailerId) {
            REQUEST_PARAMS = REQUEST_PARAMS.set('trailerId', trailerId);
        }
        return this.http
            .get<any>(REQUEST_URI, {
                params: REQUEST_PARAMS,
                context: createRequestContext({errorSeverity})
            })
            .toPromise();
    }

    getDriverById(driverId: number): Promise<IDriver> {
        const params = new HttpParams().set('id', driverId);
        const REQUEST_URI = SERVER_API_URL + 'api/drivers/' + driverId;
        return this.http.get<IDriver>(REQUEST_URI, {params: params}).toPromise();
    }

    getDriverFutureResources(driverId: number): Observable<any[]> {
        const params = new HttpParams().set('id', driverId);
        return this.http.get<any[]>(SERVER_API_URL + 'api/drivers/resource-future', {params: params});
    }

    getFullName = (driver: IDriver): string => {
        return `${driver.firstName}${driver.lastName != null ? ' ' + driver.lastName : ''}`;
    };

    getTravelNotesForDrivers(driverIds): Promise<any> {
        const params = new HttpParams().set('driverIds', driverIds);
        return this.http
            .get<IDriver[]>(`${SERVER_API_URL}api/drivers/travel-notes`, {
                params: params
            })
            .toPromise();
    }

    downloadTimesheet(startDate: string): Observable<any> {
        const REQUEST_PARAMS = new HttpParams().set('startDate', startDate);
        const REQUEST_URI = SERVER_API_URL + 'api/drivers/timesheet/export';
        return this.http.get(REQUEST_URI, {
            params: REQUEST_PARAMS,
            responseType: 'arraybuffer'
        });
    }

    downloadWorkReport(startDate: string, endDate: string): Observable<any> {
        const dt = new Date();
        const REQUEST_PARAMS = new HttpParams().set('startDate', startDate).set('endDate', endDate).set('timeZone', dt.getTimezoneOffset());
        const REQUEST_URI = SERVER_API_URL + 'api/drivers/cost-report/export';
        return this.http.get(REQUEST_URI, {
            params: REQUEST_PARAMS,
            responseType: 'arraybuffer'
        });
    }

    activateResource = (id: number): Promise<any> => {
        return this.http
            .put<any>(`${SERVER_API_URL}api/activate-driver/${id}`, null, {
                observe: 'response'
            })
            .toPromise();
    };

    deleteInactiveResource = (): Promise<any> => {
        const REQUEST_URI = `${SERVER_API_URL}api/drivers/delete-inactive`;
        return this.http.delete(REQUEST_URI).toPromise();
    };

    getDriverAvailabilityReport = (req): Promise<any[]> => {
        const options = createRequestOption2(req);

        const REQUEST_URI = SERVER_API_URL + 'api/reports/driver-availability';
        return this.http
            .get<any[]>(REQUEST_URI, {
                params: options
            })
            .toPromise();
    };

    messageDriver = (driverId, message): Promise<any> => {
        const REQUEST_PARAMS = new HttpParams().set('message', message);

        const REQUEST_URI = SERVER_API_URL + 'api/drivers/' + driverId + '/message';
        return this.http
            .post<any[]>(REQUEST_URI, null, {
                params: REQUEST_PARAMS
            })
            .toPromise();
    };

    markDriverAsCalled = (driverId): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/drivers/' + driverId + '/mark-as-called';
        return this.http.post<any[]>(REQUEST_URI, null, {}).toPromise();
    };

    bulkMessageDrivers = (driverIds, message): Promise<any> => {
        const REQUEST_PARAMS = new HttpParams().set('message', message).set('driverIds', driverIds);

        const REQUEST_URI = SERVER_API_URL + 'api/drivers/bulk-message';
        return this.http
            .post<any[]>(REQUEST_URI, null, {
                params: REQUEST_PARAMS
            })
            .toPromise();
    };

    removeDriverFromReport = (driverId): Promise<any> => {
        const REQUEST_URI = SERVER_API_URL + 'api/drivers/' + driverId + '/remove-report';
        return this.http.post<any[]>(REQUEST_URI, {}).toPromise();
    };

    getResourcedLegsForDriver = (id: number): Promise<any> => {
        return this.http.get<any>(`${SERVER_API_URL}api/legs/resourced-for-driver/${id}`).toPromise();
    };

    deleteDriver = (id: number, errorSeverity = ErrorSeverity.ERROR): Observable<any> => {
        return this.http.delete<any>(`${SERVER_API_URL}api/drivers/${id}`, {
            context: createRequestContext({errorSeverity})
        });
    };

    getAllUnplannedDrivers(from: moment.Moment, to: moment.Moment) {
        const params = new HttpParams().set('from', from.toISOString()).set('to', to.toISOString());
        return this.http
            .get<IDriver[]>(`${SERVER_API_URL}api/drivers/unplanned`, {
                params: params
            })
            .toPromise();
    }

    giveResource = (driverId, controlAreaId, trafficAreaId): Promise<any> => {
        const REQUEST_PARAMS = new HttpParams().set('trafficAreaId', trafficAreaId).set('controlAreaId', controlAreaId);

        const REQUEST_URI = `${SERVER_API_URL}api/drivers/${driverId}/give-resource`;
        return this.http
            .post<any[]>(REQUEST_URI, null, {
                params: REQUEST_PARAMS
            })
            .toPromise();
    };
}
