import {IExtra} from 'app/blocks/model/extra.model';
import {GtPalletForce, IGtPalletForce} from 'app/blocks/model/gt-pallet-force.model';
import {IGtCar} from 'app/blocks/model/gt-car.model';
import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';
import {ICustomGoodsType, CustomGoodsType} from 'app/blocks/model/custom-goods-type.model';
import {IExchangeRate, ExchangeRate} from 'app/blocks/model/exchange-rate.model';
import {ILocationPoint, LocationPoint} from 'app/blocks/model/location-point.model';
import {INote, Note} from 'app/blocks/model/note.model';
import {IOrganisation, Organisation} from 'app/blocks/model/organisation.model';
import {IServiceLevel, ServiceLevel} from 'app/blocks/model/service-level.model';
import {ITimeWindow, TimeWindow} from 'app/blocks/model/time-window.model';
import {ITpVehicleSkill} from 'app/blocks/model/tp-vehicle-skill.model';
// import { InvoiceStatus } from './invoice.model';
import {BusinessType, IBusinessType} from 'app/blocks/model/business-type.model';
import {IHmFile} from 'app/blocks/model/hm-file.model';
import {IOrderGroup} from 'app/blocks/model/order-group.model';
import {IBaseGoodsType} from 'app/blocks/model/base-goods-type.model';
import {IUser, User} from 'app/core/user/user.model';
import {IOrganisationCustomReference} from 'app/blocks/model/custom-reference.model';
import {IResourceIcon} from 'app/blocks/model/resource-icon.model';
import {IGoodsInfo} from 'app/blocks/model/goods-info.model';

export enum InvoiceStatus {
    NOT_PREPARED,
    PROFORMA,
    PRE_INVOICE,
    READY,
    INVOICED
}

export enum OrderStatus {
    PENDING = 'PENDING',
    AWAITING_APPROVAL = 'AWAITING_APPROVAL',
    APPROVED = 'APPROVED',
    DELIVERED = 'DELIVERED',
    INVOICED = 'INVOICED',
    CANCELLED = 'CANCELLED',
    REJECTED = 'REJECTED',
    QUOTE = 'QUOTE',
    QUOTE_SENT_TO_CUSTOMER = 'QUOTE_SENT_TO_CUSTOMER',
    QUOTE_APPROVED_BY_CUSTOMER = 'QUOTE_APPROVED_BY_CUSTOMER',
    QUOTE_REJECTED_BY_CUSTOMER = 'QUOTE_REJECTED_BY_CUSTOMER',
    AWAITING_POINT_INTEGRATION = 'AWAITING_POINT_INTEGRATION'
}

export enum BookStatus {
    BOOK_IN_REQUIRED = 'BOOK_IN_REQUIRED',
    BOOKED = 'BOOKED',
    NOT_REQUIRED = 'NOT_REQUIRED'
}

export enum OrderBookingType {
    WINDOW = 'WINDOW',
    TIMED = 'TIMED',
    ANYTIME = 'ANYTIME'
}

export interface IHaulierOrder extends IBaseEntity {
    id?: number;
    orderNo?: string;
    orderStatus?: OrderStatus;
    subContracted?: boolean;
    subContractNo?: string;
    collectionPlanned?: boolean;
    deliveryPlanned?: boolean;
    delivered?: boolean;
    invoiceStatus?: InvoiceStatus;
    requestDateTime?: any;
    collectDuringOfficeHours?: boolean;
    bookingEmailSent?: boolean;
    deliverDuringOfficeHours?: boolean;
    docketNumber?: string;
    loadNumber?: string;
    tariffOverride?: boolean;
    rate?: number;
    foreignRate?: number;
    fuelSurchargeOverride?: boolean;
    fuelSurcharge?: number;
    foreignFuelSurcharge?: number;
    collectionWindow?: ITimeWindow;
    plannedWindow?: ITimeWindow;
    deliveryWindow?: ITimeWindow;
    deliveryBookingType?: string;
    collectionBookingType?: string;
    instructions?: INote;
    trafficNotes?: INote;
    collectionNotes?: INote;
    deliveryNotes?: INote;
    internalComments?: INote;
    baseGoodsType?: any;
    customer?: IOrganisation;
    customerId?: number;
    customerName?: string;
    deliveryPoint?: ILocationPoint;
    deliveryPointId?: number;
    deliveryPointTrafficAreaId: number;
    deliveryPointAwaitingApproval?: boolean;
    collectionPoint?: ILocationPoint;
    collectionPointId?: number;
    collectionPointTrafficAreaId: number;
    collectionPointAwaitingApproval?: boolean;
    collectionPointRequiredSkills?: ITpVehicleSkill[];
    collectionTrafficAreaRequiredSkills?: ITpVehicleSkill[];
    deliveryPointRequiredSkills?: ITpVehicleSkill[];
    deliveryTrafficAreaRequiredSkills?: ITpVehicleSkill[];
    requestTakenById: number;
    customGoodsTypeId?: number;
    customGoodsType?: ICustomGoodsType;
    goodsInfo?: any;
    sequence?: number;
    gtShortCode?: string;
    // businessType?: IBusinessType; Just an ID
    businessType?: IBusinessType;
    businessTypeDetails?: IBusinessType;
    businessTypeDescription?: string;
    businessTypeId?: number;
    serviceLevel?: IServiceLevel;
    serviceLevelId?: number;
    serviceLevelDescription?: string;
    exchangeRate?: IExchangeRate;
    exchangeRateId?: number;
    extras?: IExtra[];
    runIds?: number[];
    orderDocuments?: IHmFile[];
    legsForOrder?: any;
    podNotAvailable?: boolean;
    readyToInvoice?: boolean;
    bookStatus: BookStatus;
    orderGroup?: IOrderGroup;
    rejectionReason?: string;
    maxGoodsTypeValue?: number;
    hasChildren?: boolean;
    onRunRate?: number;
    podInfo?: any;
    createRun?: boolean;
    rebuildRun?: boolean;
    createQuote?: boolean;
    quoteNotes?: INote;
    createdBy?: IUser;
    updatedBy?: IUser;
    updatedOn?: any;
    orderSource?: any;
    collectionAddress1?: any;
    loadNo?: any;
    docketNo?: any;
    collectionPointTown?: any;
    collectionPointStartDate?: any;
    collectionPointStartTime?: any;
    deliveryPointStartDate?: any;
    deliveryPointStartTime?: any;
    deliveryPointTown?: any;
    deliveryAddress1?: any;
    deliveryPointEndDate?: string;
    deliveryPointEndTime?: string;
    integrationDeliveryCustomer?: string;
    expectedVehicleTypeId?: number;
    customReferences?: IOrganisationCustomReference[];
    preInvoiceBatchId?: number;
    nominalCodes?: string;
    invoiceIds?: string;
    separatePreInvoice?: boolean;
    rateCard?: string;
    splitOnCreation?: boolean;
    getGoodsValue(): any;
    mileage?: number;
    resourceIcons?: IResourceIcon[];
    bookingInFormPdf: IHmFile;

    hasInvoicingProblems?: boolean;

    bookedInWith?: string;
    bookedInReferences?: string;
    bookedInDateTime?: string;
    deviationReason?: any;

    prePaid?: boolean;

    goodsInfos?: IGoodsInfo[];
}

export class HaulierOrder extends BaseEntity implements IHaulierOrder {
    public id?: number;
    public orderNo?: string;
    public orderStatus?: OrderStatus;
    public subContracted?: boolean;
    public subContractNo?: string;
    public collectionPlanned?: boolean;
    public deliveryPlanned?: boolean;
    public delivered?: boolean;
    public invoiceStatus?: InvoiceStatus;
    public requestDateTime?: any;
    public collectDuringOfficeHours?: boolean;
    public deliverDuringOfficeHours?: boolean;
    public docketNumber?: string;
    public loadNumber?: string;
    public tariffOverride?: boolean;
    public bookingEmailSent?: boolean;
    public rate?: number;
    public foreignRate?: number;
    public fuelSurchargeOverride?: boolean;
    public fuelSurcharge?: number;
    public foreignFuelSurcharge?: number;
    public collectionWindow?: ITimeWindow;
    public plannedWindow?: ITimeWindow;
    public deliveryWindow?: ITimeWindow;
    public deliveryBookingType?: string;
    public collectionBookingType?: string;
    public instructions?: INote;
    public trafficNotes?: INote;
    public collectionNotes?: INote;
    public deliveryNotes?: INote;
    public internalComments?: INote;
    public customer?: IOrganisation;
    public customerName?: string;
    public customerId?: number;
    public deliveryPointTrafficAreaId: number;
    public collectionPointTrafficAreaId: number;
    public deliveryPointAwaitingApproval: boolean;
    public collectionPointAwaitingApproval: boolean;
    public collectionPointRequiredSkills?: ITpVehicleSkill[];
    public collectionTrafficAreaRequiredSkills?: ITpVehicleSkill[];
    public deliveryPointRequiredSkills?: ITpVehicleSkill[];
    public deliveryTrafficAreaRequiredSkills?: ITpVehicleSkill[];
    public deliveryPoint?: ILocationPoint;
    public deliveryPointId?: number;
    public collectionPoint?: ILocationPoint;
    public collectionPointId?: number;
    public requestTakenById: number;
    public customGoodsType?: ICustomGoodsType;
    public goodsInfo?: any;
    public customGoodsTypeId?: number;
    public businessType?: IBusinessType;
    public businessTypeDetails?: IBusinessType;
    public businessTypeId?: number;
    public businessTypeDescription?: string;
    public serviceLevel?: IServiceLevel;
    public serviceLevelId?: number;
    public serviceLevelDescription?: string;
    public exchangeRate?: IExchangeRate;
    public exchangeRateId?: number;
    public extras?: IExtra[];
    public runIds?: number[];
    public createRun?: boolean;
    public orderDocuments?: IHmFile[];
    public legsForOrder?: any;
    public podNotAvailable?: boolean;
    public readyToInvoice?: boolean;
    public bookStatus: BookStatus;
    public orderGroup?: IOrderGroup;
    public rejectionReason?: string;
    public maxGoodsTypeValue?: number;
    public baseGoodsType?: any;
    public onRunRate?: number;
    public podInfo?: any;
    public createQuote?: boolean;
    public quoteNotes?: INote;
    public createdBy?: User;
    public updatedBy?: User;
    public updatedOn?: any;
    public collectionAddress1?: any;
    public loadNo?: any;
    public docketNo?: any;
    public collectionPointTown?: any;
    public collectionPointStartDate?: any;
    public collectionPointStartTime?: any;
    public deliveryPointStartDate?: any;
    public deliveryPointStartTime?: any;
    public deliveryPointTown?: any;
    public deliveryAddress1?: any;
    public orderSource?: any;
    public sequence?: number;
    public deliveryPointEndDate?: string;
    public deliveryPointEndTime?: string;
    public integrationDeliveryCustomer?: string;
    public expectedVehicleTypeId?: number;
    public gtShortCode?: string;
    public customReferences?: IOrganisationCustomReference[];
    public preInvoiceBatchId?: number;
    public nominalCodes?: string;
    public invoiceIds?: string;
    public separatePreInvoice?: boolean;
    public rateCard?: string;
    public splitOnCreation?: boolean;
    public mileage?: number;
    resourceIcons?: IResourceIcon[];
    public bookingInFormPdf: IHmFile;

    public hasInvoicingProblems?: boolean;
    public bookedInWith?: string;
    public bookedInReferences?: string;
    public bookedInDateTime?: string;
    public deviationReason?: any;
    public prePaid?: boolean = false;

    public goodsInfos?: IGoodsInfo[];
    public rebuildRun?: boolean;

    constructor(haulierOrder?) {
        super();
        if (haulierOrder) {
            this.id = haulierOrder.id;
            this.orderNo = haulierOrder.orderNo;
            this.orderStatus = haulierOrder.orderStatus;
            this.subContracted = haulierOrder.subContracted;
            this.subContractNo = haulierOrder.subContractNo;
            this.collectionPlanned = haulierOrder.collectionPlanned;
            this.deliveryPlanned = haulierOrder.deliveryPlanned;
            this.legsForOrder = haulierOrder.legsForOrder;
            this.delivered = haulierOrder.delivered;
            this.createRun = haulierOrder.createRun || false;
            this.invoiceStatus = haulierOrder.invoiceStatus;
            this.orderDocuments = haulierOrder.orderDocuments;
            this.podNotAvailable = haulierOrder.podNotAvailable;
            this.readyToInvoice = haulierOrder.readyToInvoice;
            this.bookingInFormPdf = haulierOrder.bookingInFormPdf;
            // FIXME: has to be made DiDateTime
            this.requestDateTime = haulierOrder.requestDateTime;
            this.bookingEmailSent = haulierOrder.bookingEmailSent;
            this.collectDuringOfficeHours = haulierOrder.collectDuringOfficeHours;
            this.deliverDuringOfficeHours = haulierOrder.deliverDuringOfficeHours;
            this.docketNumber = haulierOrder.docketNumber;
            this.loadNumber = haulierOrder.loadNumber;
            this.tariffOverride = haulierOrder.tariffOverride;
            this.rate = haulierOrder.rate;
            this.foreignRate = haulierOrder.foreignRate;
            this.fuelSurchargeOverride = haulierOrder.fuelSurchargeOverride;
            this.fuelSurcharge = haulierOrder.fuelSurcharge;
            this.foreignFuelSurcharge = haulierOrder.foreignFuelSurcharge;
            this.collectionWindow = haulierOrder.collectionWindow ? new TimeWindow(haulierOrder.collectionWindow) : haulierOrder.collectionWindow;
            this.plannedWindow = haulierOrder.plannedWindow;
            this.deliveryWindow = haulierOrder.deliveryWindow ? new TimeWindow(haulierOrder.deliveryWindow) : haulierOrder.deliveryWindow;
            this.instructions = haulierOrder.instructions ? haulierOrder.instructions : new Note();
            this.trafficNotes = haulierOrder.trafficNotes ? haulierOrder.trafficNotes : new Note();
            this.collectionNotes = haulierOrder.collectionNotes ? haulierOrder.collectionNotes : new Note();
            this.deliveryNotes = haulierOrder.deliveryNotes ? haulierOrder.deliveryNotes : new Note();
            this.internalComments = haulierOrder.internalComments ? haulierOrder.internalComments : new Note();
            // this.baseGoodsType = haulierOrder.baseGoodsType;
            this.deliveryPoint = haulierOrder.deliveryPoint;
            this.deliveryPointId = haulierOrder.deliveryPointId;
            this.collectionPoint = haulierOrder.collectionPoint;
            this.collectionPointId = haulierOrder.collectionPointId;
            this.deliveryPointTrafficAreaId = haulierOrder.deliveryPointTrafficAreaId;
            this.collectionPointTrafficAreaId = haulierOrder.collectionPointTrafficAreaId;
            this.deliveryPointAwaitingApproval = haulierOrder.deliveryPointAwaitingApproval;
            this.collectionPointAwaitingApproval = haulierOrder.collectionPointAwaitingApproval;
            this.collectionPointRequiredSkills = haulierOrder.collectionPointRequiredSkills;
            this.collectionTrafficAreaRequiredSkills = haulierOrder.collectionTrafficAreaRequiredSkills;
            this.deliveryPointRequiredSkills = haulierOrder.deliveryPointRequiredSkills;
            this.deliveryTrafficAreaRequiredSkills = haulierOrder.deliveryTrafficAreaRequiredSkills;
            this.requestTakenById = haulierOrder.requestTakenById;
            this.customGoodsType = haulierOrder.customGoodsType;
            this.customGoodsTypeId = haulierOrder.customGoodsTypeId;
            this.businessType = haulierOrder.businessType;
            this.businessTypeId = haulierOrder.businessTypeId;
            this.deviationReason = haulierOrder.deviationReason;
            this.businessTypeDescription = haulierOrder.businessTypeDescription;
            this.serviceLevel = haulierOrder.serviceLevel;
            this.serviceLevelId = haulierOrder.serviceLevelId;
            this.serviceLevelDescription = haulierOrder.serviceLevelDescription;
            this.exchangeRate = haulierOrder.exchangeRate;
            this.exchangeRateId = haulierOrder.exchangeRateId;
            this.customer = haulierOrder.customer;
            this.customerName = haulierOrder.customerName;
            this.customerId = haulierOrder.customerId;
            this.goodsInfo = haulierOrder.goodsInfo;
            this.extras = haulierOrder.extras;
            this.runIds = haulierOrder.runIds || [];
            this.bookStatus = haulierOrder.bookStatus;
            this.orderGroup = haulierOrder.orderGroup;
            this.rejectionReason = haulierOrder.rejectionReason;
            this.maxGoodsTypeValue = haulierOrder.maxGoodsTypeValue;
            this.baseGoodsType = haulierOrder.baseGoodsType;
            this.onRunRate = haulierOrder.onRunRate;
            this.podInfo = haulierOrder.podInfo;
            this.orderSource = haulierOrder.orderSource;
            this.createQuote = haulierOrder.createQuote;
            this.quoteNotes = haulierOrder.quoteNotes;
            this.createdBy = haulierOrder.createdBy;
            this.updatedBy = haulierOrder.updatedBy;
            this.updatedOn = haulierOrder.updatedOn;
            this.collectionAddress1 = haulierOrder.collectionAddress1;
            this.loadNo = haulierOrder.loadNo;
            this.docketNo = haulierOrder.docketNo;
            this.collectionPointTown = haulierOrder.collectionPointTown;
            this.collectionPointStartDate = haulierOrder.collectionPointStartDate;
            this.collectionPointStartTime = haulierOrder.collectionPointStartTime;
            this.deliveryPointStartDate = haulierOrder.deliveryPointStartDate;
            this.deliveryPointStartTime = haulierOrder.collectionPointStartTime;
            this.deliveryPointTown = haulierOrder.deliveryPointTown;
            this.deliveryAddress1 = haulierOrder.deliveryAddress1;
            this.bookedInReferences = haulierOrder.bookedInReferences;
            this.bookedInWith = haulierOrder.bookedInWith;
            this.bookedInDateTime = haulierOrder.bookedInDateTime;
            if (this.businessTypeId && this.businessTypeDescription) {
                this.businessTypeDetails = new BusinessType({
                    id: this.businessTypeId,
                    description: this.businessTypeDescription
                });
            }
            if (haulierOrder.collectionBookingType) {
                this.collectionBookingType = haulierOrder.collectionBookingType;
            } else {
                this.collectionBookingType = 'WINDOW';
            }
            if (haulierOrder.deliveryBookingType) {
                this.deliveryBookingType = haulierOrder.deliveryBookingType;
            } else {
                this.deliveryBookingType = 'WINDOW';
            }
            this.sequence = 0;
            this.deliveryPointEndDate = haulierOrder.deliveryPointEndDate;
            this.deliveryPointEndTime = haulierOrder.deliveryPointEndTime;
            this.integrationDeliveryCustomer = haulierOrder.integrationDeliveryCustomer;
            this.expectedVehicleTypeId = haulierOrder.expectedVehicleTypeId;
            if (haulierOrder.gTShortCode) {
                this.gtShortCode = haulierOrder.gTShortCode;
            } else {
                this.gtShortCode = '';
            }
            this.customReferences = haulierOrder.customReferences || [];
            this.preInvoiceBatchId = haulierOrder.preInvoiceBatchId;
            this.invoiceIds = haulierOrder.invoiceIds;
            this.nominalCodes = haulierOrder.nominalCodes;
            this.separatePreInvoice = haulierOrder.separatePreInvoice;
            this.rateCard = haulierOrder.rateCard;
            this.splitOnCreation = haulierOrder.splitOnCreation;
            this.mileage = haulierOrder.mileage;
            this.resourceIcons = haulierOrder.resourceIcons;
            this.hasInvoicingProblems = haulierOrder.hasInvoicingProblems;
            this.prePaid = haulierOrder.prePaid;

            this.goodsInfos = haulierOrder.goodsInfos || [];
            this.rebuildRun = haulierOrder.rebuildRun;
        } else {
            this.orderNo = '';
            this.orderStatus = OrderStatus.PENDING;
            this.subContracted = false;
            this.subContractNo = '';
            this.collectionPlanned = false;
            this.deliveryPlanned = false;
            this.delivered = false;
            this.invoiceStatus = InvoiceStatus.NOT_PREPARED;
            this.requestDateTime = '';
            this.collectDuringOfficeHours = false;
            this.deliverDuringOfficeHours = false;
            this.docketNumber = '';
            this.loadNumber = '';
            this.tariffOverride = false;
            this.rate = 0;
            this.foreignRate = 0;
            this.fuelSurchargeOverride = false;
            this.fuelSurcharge = 0;
            this.foreignFuelSurcharge = 0;
            this.collectionWindow = new TimeWindow();
            this.plannedWindow = new TimeWindow();
            this.deliveryWindow = new TimeWindow();
            this.deliveryNotes = new Note();
            this.collectionNotes = new Note();
            this.instructions = new Note();
            this.internalComments = new Note();
            this.trafficNotes = new Note();
            this.deliveryPoint = new LocationPoint();
            this.deliveryPointId = 0;
            this.deliveryPointAwaitingApproval = false;
            this.collectionPoint = new LocationPoint();
            this.collectionPointId = 0;
            this.collectionPointAwaitingApproval = false;
            this.serviceLevelDescription = '';
            this.customer = new Organisation();
            this.customerName = '';
            this.customerId = 0;
            this.goodsInfo = '';
            this.extras = [];
            this.bookStatus = BookStatus.NOT_REQUIRED;
            this.quoteNotes = new Note();
            this.collectionBookingType = 'WINDOW';
            this.deliveryBookingType = 'WINDOW';
            this.sequence = 0;
            this.deliveryPointEndDate = '';
            this.deliveryPointEndTime = '';
            this.customReferences = [];
            this.separatePreInvoice = false;
            this.nominalCodes = '';
            this.invoiceIds = '';
            this.hasInvoicingProblems = false;
            this.goodsInfos = [];
            this.rebuildRun = false;
        }
    }
    get discriminator(): any {
        return this.orderNo;
    }

    clone(): IHaulierOrder {
        return new HaulierOrder(this);
    }

    getGoodsValue(): any {
        if (this.baseGoodsType) {
            switch (this.baseGoodsType.type) {
                case 'PALLET':
                    return this.baseGoodsType.pallets;
                case 'LIQUID':
                    return this.baseGoodsType.litres;
                default:
                    return 0;
            }
        }
    }
}
