<div class="di-toolbar px-8" fxFlex>
    <div fxLayout="row" fxLayoutAlign="start center" class="tenant">
        {{ tenant }}
    </div>
    <div fxFlex fxFill fxLayout="row" fxLayoutAlign="end center">
        <div class="radio" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">
            <button *ngIf="!isSuperuser" nz-button nzType="primary" class="mx-8 mb-6" (click)="addNewOrderWindowed()">
                <i nz-icon nzType="plus" class="mr-2"></i>
                <span>Add Windowed</span>
            </button>
            <button *ngIf="!isSuperuser" nz-button nzType="primary" class="mx-8 mb-6" (click)="addNewOrder()">
                <i nz-icon nzType="plus" class="mr-2"></i>
                <span>Add Order</span>
            </button>
            <button *ngIf="!isSuperuser" nz-button class="mx-8 mb-6" (click)="openCheckRouteModal()">
                <i nz-icon nzType="filter" class="mr-2"></i><span>Check Route</span>
            </button>

            <form *ngIf="!isSuperuser" nz-form [formGroup]="quickSearchForm">
                <div class="searchDiv">
                    <!-- <input type="checkbox" formControlName="filterDates" />
                    <span>FilterDates</span> -->
                    <input type="radio" value="Order" formControlName="searchType" />
                    <span>Order</span>
                    <input type="radio" value="Run" formControlName="searchType" />
                    <span>Run</span>
                    <div class="searchInput">
                        <nz-input-group [nzSuffix]="suffixIconSearchRemove">
                            <input type="text" nz-input #searchInput formControlName="searchQuery" (keyup)="onSearchValueChange()" />
                        </nz-input-group>
                        <ng-template #suffixIconSearchRemove>
                            <i nz-icon nzType="close-circle" nzTheme="twotone" (click)="clearSearch()"></i>
                        </ng-template>
                    </div>
                    <button nz-button class="mx-8 mb-6" (click)="onSearch()"><i nz-icon nzType="filter" class="mr-2"></i><span>Search</span></button>
                </div>
            </form>
            <div *ngIf="!isSuperuser" [satPopoverAnchor]="notifications" style="width: 50px">
                <span (click)="notifications.toggle()">
                    <nz-badge [nzCount]="(notifications$ | async).length" nzSize="small">
                        <i>
                            <mat-icon>notifications</mat-icon>
                        </i>
                    </nz-badge>
                </span>
            </div>
            <div *ngIf="!isSuperuser" [satPopoverAnchor]="login" style="width: 50px">
                <b (click)="login.toggle()"><mat-icon>person</mat-icon></b
                >{{ (this._loggedInServie.loggedInData | async).length }}
            </div>

            <div *ngIf="bulkProgress$ ? bulkProgress$.value.length > 0 : false" [satPopoverAnchor]="bulkProgress" style="width: 50px">
                <span (click)="bulkProgress.toggle()">
                    <nz-badge [nzCount]="(bulkProgress$ | async).length" nzSize="small">
                        <i>
                            <mat-icon>hourglass_empty</mat-icon>
                        </i>
                    </nz-badge>
                </span>
            </div>

            <ai-input *ngIf="!isSuperuser"></ai-input>

            <nz-divider nzType="vertical"></nz-divider>

            <div class="px-24 py-12">
                <a nz-dropdown [nzDropdownMenu]="langMenu">
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img class="flag mr-8" [src]="'assets/images/flags/' + selectedLanguage.flag + '.png'" />
                        <span class="iso text-uppercase">{{ selectedLanguage.id }}</span>
                    </div>
                </a>
                <nz-dropdown-menu #langMenu="nzDropdownMenu">
                    <ul nz-menu nzSelectable>
                        <li nz-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
                            <span fxLayout="row" fxLayoutAlign="start center">
                                <img class="flag mr-16" [src]="'assets/images/flags/' + lang.flag + '.png'" />
                                <span class="iso">{{ lang.title }}</span>
                            </span>
                        </li>
                    </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </div>
</div>

<div class="app-version">v{{ appVersionNo }}</div>

<sat-popover horizontalAlign="end" verticalAlign="below" #notifications hasBackdrop>
    <div class="bordered-popover">
        <div class="popover-title" style="margin: 5px">
            <span><b class="title">Notifications</b></span>
            <button nz-tooltip nzTooltipTitle="Mark all as read" (click)="markNotificationsAsRead()">
                <fa-icon [icon]="faBell"></fa-icon>
            </button>
        </div>

        <div style="max-height: 150px; overflow: auto">
            <perfect-scrollbar>
                <div *ngFor="let notification of notifications$ | async" class="notification">
                    <span class="em9">
                        {{ notification.notification }}
                    </span>

                    <span class="em9" style="font-size: 9px">
                        {{ getDateTimeAsString(notification.time) }}
                    </span>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</sat-popover>

<sat-popover horizontalAlign="end" verticalAlign="below" #bulkProgress hasBackdrop>
    <div class="bordered-popover">
        <div class="popover-title" style="margin: 5px">
            <span><b class="title">Background Tasks</b></span>
        </div>

        <div style="max-height: 150px; overflow: auto">
            <perfect-scrollbar>
                <div style="margin: 20px" *ngFor="let bulkProgress of bulkProgress$ | async" class="notification">
                    <div style="min-width: 300px">
                        <b>{{ bulkProgress.operationName }}</b>
                    </div>
                    <div>Task ID:{{ bulkProgress.id }}</div>
                    <div>
                        <span style="padding-right: 5px">Succeded:{{ bulkProgress.successCount }}</span
                        ><span>Failed:{{ bulkProgress.failureCount }}</span>
                    </div>
                    <nz-progress
                        [nzPercent]="
                            ((bulkProgress.successCount + bulkProgress.failureCount) * 100.0) / bulkProgress.totalCount | roundTotal
                        "></nz-progress>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</sat-popover>

<sat-popover horizontalAlign="end" verticalAlign="below" #login hasBackdrop>
    <div class="bordered-popover">
        <div class="popover-title" style="margin: 5px">
            <span><b class="title">User Last Seen</b></span>
        </div>

        <div style="max-height: 150px; overflow: auto">
            <perfect-scrollbar>
                <div style="margin: 20px" *ngFor="let userData of this._loggedInServie.loggedInData | async" class="notification">
                    <div>{{ userData.user }}:{{ userData.lastLogin }}</div>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</sat-popover>
