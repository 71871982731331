import {Observable} from 'rxjs';
import {LocalStorageService, SessionStorageService} from 'ngx-webstorage';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent} from '@angular/common/http';
import {SERVER_API_URL, TRACKING_API_URL} from 'app/app.constants';
import {StringUtils} from 'app/blocks/util/string-util';
import {LocationService} from 'app/blocks/service/location';

export const AUTH_NOT_REQUIRED_URIS = [
    '/api/super-admin/authenticate',
    '/api/register',
    '/api/invitation-create-account',
    '/api/invitation-register-customer',
    '/api/public/countries',
    '/api/activate',
    '/api/authenticate',
    '/api/account/reset-password/init',
    '/api/account/reset-password/finish',
    '/api/payments/callback',
    '/management/health',
    '/management/info',
    '/swagger-resources/configuration/ui'
];

export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private localStorage: LocalStorageService,
        private sessionStorage: SessionStorageService,
        private locationService: LocationService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (
            !request ||
            !request.url ||
            (/^http/.test(request.url) &&
                !(SERVER_API_URL && request.url.startsWith(SERVER_API_URL)) &&
                !(TRACKING_API_URL && request.url.startsWith(TRACKING_API_URL)))
        ) {
            return next.handle(request);
        }

        const isAuthRequired = !AUTH_NOT_REQUIRED_URIS.some((suffix) => request != null && request.url != null && request.url.endsWith(suffix));

        const token = this.localStorage.retrieve('authenticationToken') || this.sessionStorage.retrieve('authenticationToken');

        if (isAuthRequired && StringUtils.isEmpty(token)) {
            console.log(`Request: ${request.url} is ignored since token is not set`);
            console.log(`Request: ${request.url} is ignored since token is not set`);
            throw new Error(`Authentication token is missing for url: ${request.url}`);
        }

        if (token) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + token,
                    'ngsw-bypass': 'true',
                    Location: this.locationService.latitude + ',' + this.locationService.longitude
                }
            });
        }
        return next.handle(request);
    }
}
