import {Injectable} from '@angular/core';
import {AngularFireDatabase} from '@angular/fire/compat/database';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Principal} from 'app/core/auth/principal.service';
import {UnsubscribeDirective} from 'app/common/unsubscribe.directive';
import {environment} from 'environments/environment';
import {ServerEventService} from 'app/blocks/service/server-event.service';
import {parse} from 'path';

@Injectable({
    providedIn: 'root'
})
export class LocationService {
    public longitude: number;
    public latitude: number;
    constructor() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    this.latitude = latitude;
                    this.longitude = longitude;
                },
                (error) => {}
            );
        }
    }
}
