import {PackageReturns} from 'app/blocks/model/package-returns.model';
import {AttemptedDeliveryResource} from 'app/blocks/resource/attempted-delivery-resource';
import {PriceListResource} from 'app/blocks/resource/price-list-resource';
import {ScaleResource} from 'app/blocks/resource/scale-resource';
import {ExtraTypeResource} from 'app/blocks/resource/extra-type-resource';
import {MetricResource} from 'app/blocks/resource/metric-resource';
import {NominalCodeResource} from 'app/blocks/resource/nominal-code-resource';
import {ResourceEventTypeResource} from 'app/blocks/resource/resource-event-type-resource';
import {DriverTypeResource} from 'app/blocks/resource/driver-type-resource';
import {SafetyProfileResource} from 'app/blocks/resource/safety-profile-resource';
import {FuelSurchargeGroupResource} from 'app/blocks/resource/fuel-surcharge-group-resource';
import {Injectable, Injector, Type} from '@angular/core';
import {Store} from '@ngxs/store';
import {AbstractEntityResource} from 'app/blocks/resource/abstract-entity-resource';
import {Observable} from 'rxjs';

import {DEFAULT_ENTITY_NAMED_LIST} from 'app/app.constants';
import {Resource} from 'app/constants/resource';
import {CountryResource} from 'app/blocks/resource/country-resource';
import {CurrencyResource} from 'app/blocks/resource/currency-resource';

import {DriverResource} from 'app/blocks/resource/driver-resource';
import {VehicleResource} from 'app/blocks/resource/vehicle-resource';
import {TrailerResource} from 'app/blocks/resource/trailer-resource';
import {TrailerTypeResource} from 'app/blocks/resource/trailer-type-resource';
import {HandheldProfileResource} from 'app/blocks/resource/handheld-profile-resource';
import {GetNamedEntityList} from 'app/blocks/store/actions/entity-actions';
import {VehicleClassResource} from 'app/blocks/resource/vehicle-class-resource';
import {ExchangeRateResource} from 'app/blocks/resource/exchange-rate-resource';
import {ServiceLevelResource} from 'app/blocks/resource/service-level-resource';
import {CustomGoodsTypeResource} from 'app/blocks/resource/custom-goods-type-resource';
import {TpAssetProfileResource} from 'app/blocks/resource/tp-asset-profile-resource';
import {TpVehicleSkillResource} from 'app/blocks/resource/tp-vehicle-skill-resource';
import {TpVehicleTypeResource} from 'app/blocks/resource/tp-vehicle-type-resource';
import {BusinessTypeResource} from 'app/blocks/resource/business-type-resource';
import {HaulierOrderResource} from 'app/blocks/resource/haulier-order-resource';
import {VatTypeResource} from 'app/blocks/resource/vat-type-resource';
import {DefaultVatResource} from 'app/blocks/resource/default-vat-resource';
import {LocationPointResource} from 'app/blocks/resource/location-point-resource';
import {UsersResource} from 'app/blocks/resource/user-resource';
import {PaymentResource} from 'app/blocks/resource/payment-resource';
import {TrafficAreaResource} from 'app/blocks/resource/traffic-area-resource';
import {TaxCategoryResource} from 'app/blocks/resource/tax-category.resource';
import {CreditNoteResource} from 'app/blocks/resource/credit-note-resource';
import {OrganisationResource} from 'app/blocks/resource/organisation-resource';
import {LateReasonResource} from 'app/blocks/resource/late-reason-resource';
import {ClausedDeliveryReasonResource} from 'app/blocks/resource/claused-delivery-reason-resource';
import {DriverSubTypeResource} from 'app/blocks/resource/driver-sub-type-resource';
import {DriverExpenseResource} from 'app/blocks/resource/driver-expense-resource';
import {ExpenseTypeResource} from 'app/blocks/resource/expense-type-resource';
import {ExpensePaymentMethodResource} from 'app/blocks/resource/expense-payment-method-resource';
import {ContainerTypeResource} from 'app/blocks/resource/container-type-resource';
import {ControlAreaResource} from 'app/blocks/resource/control-area-resource';
import {PersonResource} from 'app/blocks/resource/person-resource';
import {ProfileResource} from 'app/blocks/resource/profile-resource';
import {TenantResource} from 'app/blocks/resource/tenants-resource';
import {ShiftTypeResource} from 'app/blocks/resource/shift-type-resource';
import {Translation} from 'app/blocks/model/translation.model';
import {ResourceIconResource} from 'app/blocks/resource/resource-icon-resource';
import {DriverRequestTypeResource} from 'app/blocks/resource/driver-request-type-resource';
import {DeviationReasonResource} from 'app/blocks/resource/deviation-reason-resource';
import {OutstandingUnloadedItemsResource} from 'app/blocks/resource/outstanding-unloaded-items-resource';
import {SubContractorService} from 'app/blocks/service/api/sub-contractor.service';

@Injectable({
    providedIn: 'root'
})
export class NamedListService {
    private _listApiService = new Map<Resource, Type<any>>();

    constructor(
        private _store: Store,
        private _injector: Injector
    ) {
        this._initializeProviders();
    }

    for(resource: Resource, name: string = DEFAULT_ENTITY_NAMED_LIST): Observable<any[]> {
        // console.log(`[NamedListService] Requested a named list for ${resource}: ${name}`);
        const listResource = this._injector.get(this._listApiService.get(resource)) as AbstractEntityResource<any>;
        this._store.dispatch(new GetNamedEntityList(listResource, name));
        return this._store.select((state) => state.entities[resource].partialEntity[name].list);
    }

    private _initializeProviders(): void {
        this._listApiService.set(Resource.HAULIER_ORDERS, HaulierOrderResource);
        this._listApiService.set(Resource.COUNTRIES, CountryResource);
        this._listApiService.set(Resource.CURRENCIES, CurrencyResource);
        this._listApiService.set(Resource.DRIVERS, DriverResource);
        this._listApiService.set(Resource.VEHICLES, VehicleResource);
        this._listApiService.set(Resource.VEHICLE_CLASSES, VehicleClassResource);
        this._listApiService.set(Resource.TRAILERS, TrailerResource);
        this._listApiService.set(Resource.TRAILER_TYPES, TrailerTypeResource);
        this._listApiService.set(Resource.HANDHELD_PROFILES, HandheldProfileResource);
        this._listApiService.set(Resource.ORGANISATIONS, OrganisationResource);
        this._listApiService.set(Resource.EXCHANGE_RATES, ExchangeRateResource);
        this._listApiService.set(Resource.FUEL_SURCHARGE_GROUPS, FuelSurchargeGroupResource);
        this._listApiService.set(Resource.SERVICE_LEVELS, ServiceLevelResource);
        this._listApiService.set(Resource.CUSTOM_GOODS_TYPES, CustomGoodsTypeResource);
        this._listApiService.set(Resource.SAFETY_PROFILES, SafetyProfileResource);
        this._listApiService.set(Resource.DRIVER_TYPES, DriverTypeResource);
        this._listApiService.set(Resource.TP_ASSET_PROFILES, TpAssetProfileResource);
        this._listApiService.set(Resource.TP_VEHICLE_SKILLS, TpVehicleSkillResource);
        this._listApiService.set(Resource.EXTRA_TYPES, ExtraTypeResource);
        this._listApiService.set(Resource.NOMINAL_CODES, NominalCodeResource);
        this._listApiService.set(Resource.METRICS, MetricResource);
        this._listApiService.set(Resource.SCALES, ScaleResource);
        this._listApiService.set(Resource.PACKAGE_RETURNS, PackageReturns);
        this._listApiService.set(Resource.TRANSLATION, Translation);
        this._listApiService.set(Resource.TP_VEHICLE_TYPES, TpVehicleTypeResource);
        this._listApiService.set(Resource.ATTEMPTED_DELIVERY, AttemptedDeliveryResource);
        this._listApiService.set(Resource.OUTSTANDING_UNLOADED_ITEMS, OutstandingUnloadedItemsResource);
        this._listApiService.set(Resource.BUSINESS_TYPES, BusinessTypeResource);
        this._listApiService.set(Resource.RESOURCE_EVENT_TYPES, ResourceEventTypeResource);
        this._listApiService.set(Resource.RESOURCE_ICONS, ResourceIconResource);

        this._listApiService.set(Resource.VAT_TYPE, VatTypeResource);
        this._listApiService.set(Resource.DEFAULT_VAT, DefaultVatResource);
        this._listApiService.set(Resource.LOCATION_POINTS, LocationPointResource);
        this._listApiService.set(Resource.USERS, UsersResource);
        this._listApiService.set(Resource.PAYMENTS, PaymentResource);
        this._listApiService.set(Resource.TRAFFIC_AREAS, TrafficAreaResource);
        this._listApiService.set(Resource.UK_VAT_RATES, TaxCategoryResource);
        this._listApiService.set(Resource.CREDIT_NOTES, CreditNoteResource);
        this._listApiService.set(Resource.PRICE_LISTS, PriceListResource);
        this._listApiService.set(Resource.LATE_REASONS, LateReasonResource);
        this._listApiService.set(Resource.CLAUSED_DELIVERY_REASONS, ClausedDeliveryReasonResource);
        this._listApiService.set(Resource.DRIVER_SUB_TYPES, DriverSubTypeResource);
        this._listApiService.set(Resource.EXPENSES, DriverExpenseResource);
        this._listApiService.set(Resource.EXPENSE_TYPES, ExpenseTypeResource);
        this._listApiService.set(Resource.EXPENSE_PAYMENT_METHOD, ExpensePaymentMethodResource);
        this._listApiService.set(Resource.CONTAINER_TYPES, ContainerTypeResource);
        this._listApiService.set(Resource.CONTROL_AREAS, ControlAreaResource);
        this._listApiService.set(Resource.PERSONS, PersonResource);
        this._listApiService.set(Resource.PROFILES, ProfileResource);
        this._listApiService.set(Resource.TENANTS, TenantResource);
        this._listApiService.set(Resource.SHIFT_TYPES, ShiftTypeResource);
        this._listApiService.set(Resource.DRIVER_REQUEST_TYPES, DriverRequestTypeResource);
        this._listApiService.set(Resource.DEVIATION_REASON, DeviationReasonResource);
        this._listApiService.set(Resource.SUB_CONTRACTOR, SubContractorService);
    }
}
