import {BaseEntity, IBaseEntity} from 'app/blocks/model/base-entity.model';

export interface ITpVehicleSkill extends IBaseEntity {
    id?: number;
    skill_name?: string;
    skill_type?: string;
}

export class TpVehicleSkill extends BaseEntity implements ITpVehicleSkill {
    public id?: number;
    public skill_name?: string;
    public skill_type?: string;

    constructor(tpVehicleSkill?: any) {
        super();
        if (tpVehicleSkill) {
            this.id = tpVehicleSkill.id;
            this.skill_name = tpVehicleSkill.skill_name;
            this.skill_type = tpVehicleSkill.skill_type;
        }
    }

    get discriminator(): any {
        return this.id;
    }

    clone(): TpVehicleSkill {
        return new TpVehicleSkill(this);
    }
}
