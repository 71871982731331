import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import EntityCrudService from 'app/blocks/service/api/entity-service';
import {Resource} from 'app/constants/resource';
import {Organisation} from 'app/blocks/model/organisation.model';
import {SERVER_API_URL} from 'app/app.constants';
import {DiDate} from 'app/blocks/util/di-date';

@Injectable({
    providedIn: 'root'
})
export class SubContractorService extends EntityCrudService<Organisation> {
    constructor(http: HttpClient) {
        super(http, Resource.SUB_CONTRACTOR, (obj?) => new Organisation(obj));
    }

    getPrimaryEmail = (organisationid): Promise<any> => {
        return this.http.get(`${SERVER_API_URL}api/organisations/${organisationid}/primary-email`).toPromise();
    };

    getReadyToInvoice(filter: any): Promise<any> {
        return this.http
            .get<any>(this._resourceUrl + '/ready-to-invoice', {
                params: filter,
                observe: 'response'
            })
            .toPromise();
    }

    invoiceExtras(ids: number[]): Promise<any> {
        return this.http
            .post<any>(this._resourceUrl + '/invoice', ids, {
                observe: 'response'
            })
            .toPromise();
    }

    updateRates(rates: any): Promise<any> {
        return this.http
            .post<any>(this._resourceUrl + '/update-rates', rates, {
                observe: 'response'
            })
            .toPromise();
    }

    invoice(ids: number[], invoiceDate: DiDate, invoiceNoMap): Promise<any> {
        let requestParams = new HttpParams();
        if (invoiceDate) {
            requestParams = requestParams.set('invoiceDate', invoiceDate.toString());
        }
        if (invoiceNoMap) {
            requestParams = requestParams.set('invoiceRefMap', JSON.stringify(invoiceNoMap));
        }
        return this.http
            .post<any>(this._resourceUrl + '/invoice', ids, {
                observe: 'response',
                params: requestParams
            })
            .toPromise();
    }

    getExcludedSubcontractors(): Promise<any> {
        return this.http.get<any>(this._resourceUrl + '/excluded-subcontractors').toPromise();
    }

    getSubContractor(id: number): Promise<any> {
        return this.http.get<any>(`${SERVER_API_URL}api/sub-contractors/${id}`).toPromise();
    }

    saveSubContractor(data: any): Promise<any> {
        return this.http.post<any>(`${SERVER_API_URL}api/sub-contractors`, data).toPromise();
    }
}
