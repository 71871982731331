<div fxLayout="column" fxLayoutAlign="start center">
    <div fxLayout="row" fxLayoutAlign="start center" class="di-modal-title" style="width: 100%">
        <h2 fxFlex="20">{{ 'checkRouteModal.heading' | translate }}</h2>
        <div fxFlex="80" class="di-form-row" [formGroup]="form" fxLayoutAlign="space-evenly center">
            <div fxFlex="40" fxLayout="column" fxLayoutAlign="center stretch">
                <di-select
                    (selectionChange)="getRoute()"
                    field="priceListId"
                    [options$]="priceLists$"
                    valueAttribute="id"
                    displayAttribute="description"
                    [suppressLabel]="true"
                    placeholder="Choose Tariff"
                    [preSelectFirstOption]="false">
                </di-select>
            </div>
            <div fxFlex="20" *ngIf="form.value.priceListId">{{ 'checkRouteModal.rate' | translate }} : {{ rateBS$ | async | diCurrency: (currencyService.currency$ | async) }}</div>
            <div fxFlex="20" *ngIf="!form.value.priceListId" fxLayout="row" fxLayoutAlign="start center">
                <span>{{ 'checkRouteModal.rate' | translate }} {{' : '}}</span>
                <di-input field="rate" type="number" [suppressLabel]="true" (input)="rateChange()"></di-input>
            </div>
            <div fxFlex="20">{{ 'checkRouteModal.mileage' | translate }} : {{ mileageBS$ | async }}</div>
            <div fxFlex="20">
                {{ 'checkRouteModal.costPerMile' | translate }} : {{ costPerMileBS$ | async | diCurrency: (currencyService.currency$ | async) }}
            </div>
        </div>
    </div>

    <div class="di-modal-body">
        <div class="di-form-row" [formGroup]="form">
            <di-radio-group field="collectionSearchType" [options$]="searchTypes$"> </di-radio-group>
            <di-search-select
                *ngIf="form.value.collectionSearchType === 'POINT'"
                fxFlex="50"
                [grow]="false"
                field="collectionPoint"
                displayAttribute="completeAddress"
                [options$]="locationPoints$"
                (selectionChange)="collectionPointChanged($event)"
                [customSearchFn]="searchLocationPoints">
            </di-search-select>
            <di-input
                fxFlex="50"
                [grow]="false"
                *ngIf="form.value.collectionSearchType === 'ADDRESS'"
                field="collectionAddressSearch"
                (input)="initAutoComplete('COLLECT')"></di-input>
            <di-radio-group field="deliverySearchType" [options$]="searchTypes$"> </di-radio-group>
            <di-search-select
                *ngIf="form.value.deliverySearchType === 'POINT'"
                fxFlex="50"
                [grow]="false"
                field="deliveryPoint"
                displayAttribute="completeAddress"
                [options$]="locationPoints$"
                (selectionChange)="deliveryPointChanged($event)"
                [customSearchFn]="searchLocationPoints">
            </di-search-select>
            <di-input
                fxFlex="50"
                [grow]="false"
                *ngIf="form.value.deliverySearchType === 'ADDRESS'"
                field="deliveryAddressSearch"
                (input)="initAutoComplete('DELIVER')"></di-input>
        </div>
        <div #map [style.borderRadius]="'8px 8px 8px 8px'" [style.height]="'calc(100vh - 450px)'" [style.overflow]="'hidden'"></div>
        <div
            #key
            [style.width]="'120px'"
            [style.layout]="'flex'"
            [style.flex-direction]="'column'"
            [style.position]="'absolute'"
            [style.top]="'150px'"
            [style.right]="'55px'">
            <div [style.background]="predictedRouteColour" [style.padding]="'5px'">Estimated Route</div>
        </div>
    </div>
</div>
